import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { navigate } from 'gatsby-link'
import queryString from 'query-string'

import homeActions from '../state/home/actions'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Loading from '../components/loading'
import View from '../components/presentation/home'

class IndexPage extends React.Component {

  state = {
    isLoading: true,
  }

  componentDidMount() {
    const values = queryString.parse(this.props.location.search)
    this.props.onLoad({
      callback: () => {
        if (values.firstpage === 'false') {
          const phone_number = '0'
          const values = queryString.parse(this.props.location.search)
          this.props.onPhoneSubmit({
            staff_id: values.id,
            phone_number,
            app_id: '1',
          })
          this.setState({ isLoading: true }, () => {
            navigate(`/menu/?id=${values.id}&phone=${phone_number}`)
          })
        } else {
          this.setState({ isLoading: false })
        }
      },
    })
  }

  onSubmit = () => {
    const values = queryString.parse(this.props.location.search)
    this.props.onPhoneSubmit({
      staff_id: values.id,
      phone_number: this.input.value,
      app_id: '1',
    })
  }

  refInput = ref => this.input = ref

  render() {
    if (this.state.isLoading) {
      return <Loading/>
    }
    return (
      <Layout>
        <SEO keywords={[`Staffqr`]}/>
        <View
          sliderShow={this.props.state.slider_show}
          refInput={this.refInput}
          onSubmit={this.onSubmit}/>
      </Layout>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    state: state.home,
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    onLoad: homeActions.onLoad,
    onPhoneSubmit: homeActions.onPhoneSaveToTemp,
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(IndexPage)
